// Variables
@import 'variables';
@import 'mylib';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "theme";

@import "~wowjs/css/libs/animate.css";



body {
    background-color: white;
    font-family: futura-pt, sans-serif;
    // font-family: Georgia-Regular, sans-serif;
    font-style: normal;
    font-weight: 300;
}

section {
    margin-top: 40px;
    margin-bottom: 40px;
}

.section-custom {
    padding: 40px 0;
}

.high-margin {
    margin-top: 110px;
    margin-bottom: 110px;
}

figcaption {
    font-size: 16px;
    color: grey;
    font-family: futura-pt, sans-serif;
}

blockquote {
    background: #FEF0F0;

    padding: 30px 60px;
    margin: 60px auto;
    margin-top: 90px;
    width: 75%;
    font-family: futura-pt, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.45px;
    line-height: 18px;
    color: #E63C28;
    text-transform: uppercase;
    text-align: center;
    position: relative;

    &::before {
        content: "";
        width: 100%;
        background-image: url('/assets/icon-eyelash.png');
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        height: 60px;
        top: -60px;
        left: 0;
        position: absolute;
    }

    p {
        margin: 0;
    }

    p::before {
        content: open-quote;
    }

    p::after {
        content: close-quote;
    }

}


blockquote.style2 {
    background: #E63C28;
    padding: 30px 60px;
    margin: 60px auto;
    width: 100%;
    font-family: futura-pt, sans-serif;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 2.24px;
    line-height: 35px;
    color: white;
    text-align: center;
    text-transform: none;

    &::before {
        content: none;
    }
}





.container {
    max-width: 1080px !important;
    padding-right: 0;
    padding-left: 0;

    .main-side-container {
        display: flex;

        .main-side-left {
            width: 728px;
            padding: 0;
        }

        .main-side-right {
            flex-grow: 1;
            padding: 0;
        }
    }
}

h2 {
    font-size: 30px;
    display: inline;
    font-weight: bold;
    margin: 0px;

    span {
        background-color: white;
        padding: 3px 15px;
        -webkit-box-decoration-break: clone;
        -moz-box-decoration-break: clone;
        box-decoration-break: clone;
    }
}

.box {
    width: 50rem;
    margin: 5px auto;
    //font-family: arial, verdana, sans-serif;
}

.box-border {
    border: 1px solid #E63C28;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
}

.slider-nav {
    width: 100%;
    height: max-content;
    display: flex;
    overflow-x: auto;
}

.slide {
    flex-shrink: 0;
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;

    .active {
        height: auto;
    }
}

.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

.page-link {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.page-item.active .page-link {
    color: black;
    text-decoration: underline;
    background-color: transparent;
}

.underline-wave {
    text-decoration: none;
    padding-bottom: 10px;
}

.underline-gradient {
    padding-bottom: 4px;
    position: relative;
    text-decoration: none;
}

.underline-gradient::after {
    background: #f00;
    background: linear-gradient(to right, #E63C28, #FFD219);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
}


.line-limiter-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.modal-backdrop.show {
    opacity: 0.85;
}

#modal-article-share {
    font-family: futura-pt, sans-serif;
    font-weight: 500;

    .modal-content {
        border-radius: 12px;
    }

    .modal-dialog {
        width: 250px;

        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .modal-header {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E63C28;
        text-align: center !important;
        color: white;

        font-size: 18px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        padding: 30px;

        font-size: 14px;

        .content {
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 8px 0;

            .txt-content {
                padding: 0 10px;
            }

            .img-content {
                img {
                    max-height: 24px;
                    max-width: 24px;
                }
            }
        }
    }
}

#full-screen-over {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.88);
    display: none;
}

#bg-dark-generic {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
}

.section-line-break {
    hr {
        border-top: 2px solid grey;
    }
}

.main-content-container {
    //max-width: 1365px;
    margin: auto;

    .side-ads-banner {
        width: 120px;
        height: 600px;
        background-color: #2EB6FC !important;
        margin: 0;
        position: sticky;
        top: 90px;
    }
}

#skin-ads-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1360px;
    pointer-events: none;

    .side-ads-banner {
        width: 120px;
        height: 600px;
        background-color: #2EB6FC !important;

        pointer-events: visible;
        cursor: pointer;
    }
}

.section-ad-banner {
    height: fit-content;
    margin-top: 60px;
    margin-bottom: 60px;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
    }

    .content-ad-banner {
        // width: 728px;
        // height: 90px;
        width: auto;
        height: auto;

        .ad-mockup {
            width: 728px;
            height: 90px;
            background-color: #2EB6FC;
        }
    }

    .content-ad-banner-300-250 {
        width: 300px;
        height: 250px;
    }

    .content-ad-banner-320-50 {
        margin: 0 !important;
        width: 320px;
        height: 50px;
    }
}

.section-ad-fixed-bottom {
    padding: 0;
    margin: 0;
    position: fixed;
    bottom: 0;

    z-index: 15;

    height: fit-content;
    width: 100vw;

    .container {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        width: 100%;
        height: 100%;

        padding: 0;
        background-color: white;
    }

    .content-ad-banner {
        width: 100%;
        height: 100%;
        background-color: #2EB6FC;
    }
}

.content-ad-side-sticky {
    height: 100%;
    padding-bottom: 70px;
    margin-bottom: 0;

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;

        width: 100%;
        height: 100%;
    }

    .content-ad-banner {
        position: sticky;
        top: 95px;

        .ads {
            width: 300px;
            height: 250px;
            margin-bottom: 30px;
        }
    }
}



.section-mid-banner {
    border: 0px solid grey;
    border-top: 2px;
    border-bottom: 2px;
}

.section-ad-flying-carpet {
    // height: 450px;
    height: auto;
    margin: 0;
    background-color: #CBCBCB;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 $padding-mobile;

        width: 100%;
        height: 100%;
    }

    .content-ad-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;

        width: 100%;
        height: 100%;
        background-color: #2EB6FC;
    }
}

#section-popup-page {
    margin: 0 !important;
    padding: 0 !important;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: fit-content;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        background-color: white;
        border-radius: 15px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

        padding: 40px 60px;

        align-items: center;
        justify-content: center;

        .title {
            font-size: 24px;
            color: black;

            padding-bottom: 50px;
        }
    }
}

#section-popup-player {
    height: 100vh;
    margin: 0;
    padding: 90px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: white;
    text-align: center;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .window-title {
        background-color: #E63C28;
        color: white;
        font-size: 32px;
        font-weight: bold;
        padding: 20px;
        width: 100%;
    }

    .player {
        img {
            width: 100%;
        }
    }

    .window-content {
        padding: 30px;

        .title {
            font-size: 24px;
            font-weight: bold;
            padding: 15px 0;
        }

        .time {
            font-size: 18px;
        }
    }


    .volume-control {
        padding: 0 30px;

        .img-fill-content {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .footer {
        font-size: 12px;
        letter-spacing: 1.2px;
        padding: 30px;
        position: absolute;
        bottom: 30px;
    }
}

#section-popup-profile {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: fit-content;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;

    background-color: white;

    .container {
        padding: 30px 90px;
        text-align: center;

        .menu {
            padding: 15px;
            margin: 15px 0;
            font-size: 24px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

#section-search-page {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: fit-content;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        align-items: center;
        justify-content: center;

        .title {
            font-size: 38px;
            color: white;

            padding-bottom: 90px;
        }

        form {
            margin: 0;
            padding: 0;

            display: flex;
            align-items: center;
            position: relative;
        }

        input {
            padding: 15px;
            width: 490px;
            border: 0;


            font-size: 18px;
        }

        button {
            padding: 10px 15px;
            background-color: #E63C28;
            border: 2px solid #E63C28;

            font-size: 22px;
            color: white;
        }

        .dropdown-menu {
            min-width: 0 !important;
            width: 490px;
        }

        .dropdown-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

#section-search-result {
    .top-container {
        padding-bottom: 30px;

        .result-query {
            font-size: 24px;
        }

        .result-category {
            font-size: 18px;
            font-style: italic;
        }
    }

    .result-container {
        padding-top: 30px;

        .content {
            padding-bottom: 25px;
        }
    }

}

#section-top-logo {
    .full-width {
        max-width: 100% !important;
    }

    .img-logo {
        max-height: 36px;
        width: auto;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.section-page-show-all {
    margin-top: 20px;
    margin-bottom: 20px;

    .container-author-detail {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 80px;

        .left-content {
            width: auto;
            margin-right: 30px;
        }

        .right-content {
            width: 70%;
        }

        .author-img {
            border-radius: 50%;
        }

        .author-name {
            font-size: 36px;
            font-weight: bold;
            line-height: 36px;
        }

        .author-medsos {
            font-size: 20px;
            margin-bottom: 10px;

            img {
                margin-right: 5px;
            }
        }

        .author-description {
            font-size: 18px;
            font-family: Georgia-Regular, sans-serif !important;
            color: #959595;
        }
    }
}

#section-page-banner {
    margin-top: 0px;
    margin-bottom: 0px;

    text-align: center;

    .header {
        position: relative;
        padding-bottom: 30%;
        display: grid;
        justify-items: center;

        .title {
            position: absolute !important;
            bottom: 0;
        }

        picture {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .full-width {
        max-width: 100% !important;
    }

    .title {
        padding: 60px;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        height: 480px;

        padding: 40px 20%;
    }

    .container-2 {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        text-align: left;

        width: 100%;
        max-width: 1080px !important;

        padding: 40px 0;
    }

    .edit-banner-container {
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;

        cursor: pointer;
    }

    .navigation {
        margin: 30px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .dot-active {
            height: 13px;
            width: 13px;
            background-color: #E63C28;
            border-radius: 50%;
            display: inline-block;
            border: 1px solid #E63C28;
        }

        .dot {
            height: 13px;
            width: 13px;
            background-color: transparent;
            border-radius: 50%;
            display: inline-block;
            border: 1px solid #E63C28;
        }
    }

}

#section-page-subscribe {
    margin-top: 0;
    margin-bottom: 0;

    background-position: center;
    background-size: cover;

    .container {
        display: flex;
    }

    .empty {
        width: 50%;
    }

    .main {
        width: 50%;
        padding: 80px 0;

        .title {
            font-size: 36px;
            font-weight: bold;
            padding: 15px 0;
        }

        .content {
            font-size: 18px;
            padding-bottom: 30px;
        }

        .btn-container {
            padding-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;

            .button-box {
                width: 150px;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    .title-small {
        font-size: 24px;
        font-weight: bold;
        padding: 15px 0;
    }

    .contact {
        .label {
            font-size: 12px;
        }

        .value {
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 10px;
        }
    }
}

#section-page-contact-us {
    margin-top: 0;
    margin-bottom: 0;

    .container {
        //max-width: 100% !important;
        display: flex;
        flex-direction: row;

        .side-left {
            background-repeat: no-repeat;
            background-size: cover;

            display: flex;
            width: 50%;
            align-items: center;
            justify-content: center;
            padding: 5% 0;
        }

        .side-right {
            background-repeat: no-repeat;
            background-size: cover;

            display: flex;
            width: 50%;
            align-items: center;
            justify-content: center;
            padding: 5% 0 5% 10%;
        }

        .contact-data {

            .title {
                font-size: 36px;
                font-weight: bold;
            }

            .content {
                font-size: 18px;
                margin: 15px 0;
            }

            .sub-content-container {
                padding: 15px 0;

                .sub-title {
                    font-size: 24px;
                    font-weight: bold;
                }

                .sub-content {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    padding: 8px 0;
                    display: flex;

                    .icon {
                        padding: 0 10px;
                    }
                }
            }

        }

        .contact-form {
            background-color: #D2EBE6;
            padding: 15px 45px;

            .title {
                color: #E63C28;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                margin: 30px;
            }

            .form-name {
                display: flex;
                gap: 15px;
            }

            form {
                text-align: center;
            }

            input,
            textarea {
                width: 100%;
                margin: 10px 0;
                padding: 10px 15px;
                border: 0;
                font-size: 14px;
            }

            input::placeholder,
            textarea::placeholder {
                color: #1D1D1D;
            }

            .button-box {
                border: 2px solid transparent;
                width: auto !important;
                padding: 10px 50px !important;
                margin: 30px;
            }

            .button-box:hover {
                border: 2px solid #E63C28;
                background-color: transparent;
                color: #E63C28;
            }
        }


    }

    .container-inside {
        display: flex;
        flex-direction: column;

    }
}

#section-about-content {
    font-family: Georgia-Regular, sans-serif !important;

    .container-content {
        padding: 0px 140px;
    }

    .content-main {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .content-break {
        text-align: center;
        padding: 20px 0;
    }

    .content-highlight {
        padding: 20px 40px;
        font-size: 18px;
    }

    .contact {
        padding: 5px 0;
    }

    .address {
        padding: 5px 0;
        display: flex;

        .icon {
            padding-right: 10px;
        }
    }

    .content-contact {
        font-size: 18px;
        font-weight: 500;
    }

    h3 {
        font-family: futura-pt, sans-serif;
    }

    h5 {
        margin: 0 !important;
        color: black !important;
        font-size: 12px;
    }

    img {
        max-width: 100%;
    }
}

#section-about-team {
    text-align: center;

    .container-content {
        padding: 0px 140px;
    }

    .main {
        padding: 60px 30px;
        //border: 2px solid #E63C28;
    }

    .title {
        font-size: 38px;
        font-weight: 500;
        padding-bottom: 30px;
    }

    .profiles {
        font-family: Georgia-Regular, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 30px;
        gap: 40px;

        .leader-layout {
            margin: 15px !important;
        }

        .profile-container {
            width: 20%;
            padding: 0;
            margin: auto;

            .photo {
                img {
                    max-width: 150px;
                    height: auto;
                    border-radius: 50%;
                }
            }

            .name {
                font-size: 18px;
                margin-top: 10px;
            }

            .profile-title {
                font-family: futura-pt, sans-serif;
                font-size: 12px;
                text-transform: uppercase;
            }
        }

        .leaders {
            display: flex;
            justify-content: center;
            width: 100%;

            .profile-container {
                margin: 25px;
            }
        }
    }


}

.section-profile {
    color: white;
    margin-bottom: 40px !important;

    #banner-bg {
        padding: 50px 15px 15px 15px !important;

        .container {
            padding-bottom: 80px;
        }
    }

    .photo-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-top: -100px;

        color: black;
    }

    .photo-profile {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 2px solid #E63C28;
    }

    .edit-profile-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 30px;
        color: black;
        padding: 10px 0;
        font-size: 20px;
        font-weight: 600;
        width: 100%;

        .button-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            img {
                vertical-align: 0;
            }
        }

    }
}

#section-profile-history {
    margin: 120px 0;
}

#section-profile-my-comments {
    h2 {
        span {
            padding: 0;
        }
    }

    .title {
        margin: 15px 0;
    }

    .container-comments {
        display: flex;
        width: 100%;
        margin: 45px 0;

        .content-image {
            width: 10%;
            position: relative;
            height: 0;
            padding-bottom: 10%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }
    }

    .side-right {
        display: flex;
        width: 100%;
    }

    .content-main {
        flex-grow: 2;
        padding: 0 30px;

        .comment-name {
            font-weight: 500;
            font-size: 20px;
        }

        .comment-date {
            font-size: 14px;
        }

        .comment-content {
            font-family: Georgia-Regular, sans-serif;
            color: #707070;
            font-size: 18px;
        }
    }

    .button-container {
        margin: auto;
    }

}

#section-profile-see-all {
    .title {
        padding-bottom: 45px;
    }
}

#section-profile-cover-edit {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;

    text-align: center;

    width: 600px;

    z-index: 101;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: white;
        border-radius: 15px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

        padding: 20px 60px;
    }

    .title {
        font-size: 24px;
        font-weight: bold;
        margin: 15px 0;
    }

    .btn-container {
        display: flex;
        flex-direction: row;
    }

    .button-cover {
        display: block;
        width: 160px;
        border: 2px solid #E63C28;
        background-color: #E63C28;
        font-weight: bold;
        color: white;
        padding: 12px 32px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        margin: 30px 15px;

    }

    .button-revert-color {
        border: 2px solid #E63C28 !important;
        background-color: white !important;
        color: #E63C28 !important;
    }

}

#section-profile-edit {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;

    width: 500px;

    z-index: 101;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        background-color: white;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

        padding: 60px;
    }

    .title {
        font-size: 24px;
        font-weight: bold;
    }

    .photo-profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 2px solid #E63C28;
        cursor: pointer;
    }

    .form-save-profile {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 60px;
    }

    input {
        width: 100%;
        padding: 8px 15px;
        border: 1px solid #B6B6B6;
    }

    .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0 30px 0;
    }
}

#section-profile-address {
    hr {
        color: gray;
        opacity: 0.3;
    }

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            gap: 5px;

            img {
                vertical-align: 0%;
            }
        }

        #btn-create-address {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            font-size: 12px;
            color: #E63C28;
            gap: 5px;

            img {
                vertical-align: 0%;
            }
        }
    }

    .container-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 30px 0;
        gap: 30px;

        .info {
            flex-grow: 1;

            .content-info {
                font-size: 16px;
                font-family: Georgia-Regular, sans-serif;
            }

            .type {
                font-size: 20px;
                font-weight: 600;

                .tag-default {
                    font-size: 12px;
                    font-weight: normal;
                    font-family: futura-pt, sans-serif;
                    color: #A6A6A6;
                    border: 1px solid #A6A6A6;
                    padding: 1px 3px;
                    margin: 0 15px;
                }
            }

            .note {
                margin: 30px 0;
            }

            .buttons {
                display: flex;
                flex-direction: row;
                gap: 15px;
                justify-content: space-between;

                .btn-content {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;
                }

                .btn {
                    display: flex;
                    flex-direction: row;
                    cursor: pointer;
                    font-size: 12px;
                    color: #E63C28;
                    gap: 5px;
                    padding: 0;

                    img {
                        vertical-align: 0%;
                    }
                }
            }
        }

        .map {
            width: 35%;
        }
    }

    .button-save-container {
        margin: 60px 0;
        width: fit-content;

        .button-box {
            padding-left: 60px;
            padding-right: 60px;
        }
    }
}

.section-shop {
    font-size: 20px;
    line-height: 24px;

    .panel-control-floating {
        position: fixed;
        bottom: 80px;
        right: 20px;
        display: none;
        flex-direction: column;
        gap: 10px;
        z-index: 10;

        #button-filter-mobile {
            cursor: pointer;
        }

        #button-sort-mobile {
            cursor: pointer;
        }
    }

    .panels {
        display: flex;
        flex-direction: row;

        .panel-top {
            display: flex;
            justify-content: space-between;
            height: 60px;

            .container-tag {
                display: flex;
                gap: 15px;


                .shop-tag {
                    border: 2px solid #A6A6A6;
                    padding: 5px 10px;
                    font-size: 14px;
                    font-weight: 600;
                    height: fit-content;

                    img {
                        margin-left: 15px;
                    }
                }
            }

            #button-sort {
                display: flex;
                justify-content: flex-end;
                cursor: pointer;

                .shop-sort {
                    background-color: #E63C28;
                    color: white;
                    padding: 10px 25px;
                    font-size: 14px;
                    font-weight: 600;
                    height: fit-content;

                    img {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    #container-sort {
        position: fixed;
        height: 100%;
        width: 340px;
        right: 0;
        top: 0;
        z-index: 11;
        transition: 0.5s;
        background-color: white;
        padding: 45px;

        .button-container {
            margin: 60px 0;
        }
    }

    #container-filter {
        width: 23%;
        margin-top: 60px;
        margin-right: 30px;
        transition: 0.5s;
        overflow-y: auto;
    }

    .controller {
        .title {
            font-weight: bold;
            margin-bottom: 20px;
        }

        .filter-title {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .filter-container {
            input[type='radio'] {
                margin-top: 10px;
                margin-bottom: 10px;
                margin-right: 10px;
                accent-color: #E63C28;
                height: 17px;
                width: 17px;
            }

            input[type='text'], input[type='number'] {
                font-size: 14px;
                padding: 5px 10px;
                margin: 5px 0;
                width: 100%;
            }
        }

        hr {
            color: #707070;
            opacity: 0.5;
        }
    }

    .hide-panel-right {
        position: fixed;
        width: 100% !important;
        right: -100% !important;
        top: 0 !important;
    }

    .show-panel-left {
        position: fixed;
        width: 80% !important;
        left: 0 !important;
        top: 0 !important;
    }

    .container-main {
        width: 77%;

        .result {
            margin-bottom: 20px;
            font-style: italic;
        }

        .main {
            .content-items {
                text-align: center;
                margin-bottom: 40px;

                .brand-container {
                    font-size: 11px;
                    line-height: 14px;
                    color: #E63C28;
                    font-weight: 600;
                    margin: 15px auto;
                }

                .name-container {
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 600;
                    margin: 15px auto;
                }

                .price-container {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 500;
                    margin: 15px auto;
                }
            }
        }
    }

    .filter-title {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.4px;
        font-weight: 500;
    }
}

.section-shop-detail {
    .breadcrumb {
        font-size: 14px;
        margin: 40px 0;
    }

    .container-main {
        display: flex;
        flex-direction: row;
        gap: 75px;

        .container-pic {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .thumbs-pic {
                display: flex;
                justify-content: space-around;
                gap: 15px;

                .pics-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;
                    overflow-x: auto;
                    padding-bottom: 15px;

                    .pics {
                        min-width: 80px;
                        min-height: 80px;
                        max-width: 80px;
                        max-height: 80px;
                    }
                }

                .nav-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }

        .container-data {
            width: 50%;

            .brand {
                font-size: 12px;
                color: #E63C28;
            }

            .name {
                font-size: 36px;
                font-weight: 600;
                margin: 10px 0;
            }

            .price {
                font-size: 24px;
                font-weight: 500;
                margin: 10px 0;
            }

            .description {
                font-size: 18px;
                font-family: Georgia-Regular, sans-serif;
                margin: 20px 0;
            }

            .quantity {
                font-size: 20px;
                font-weight: 500;
                display: flex;
                gap: 110px;
                margin: 20px 0;

                .controller {

                    .btn {
                        cursor: pointer;
                    }
                }
            }

            .buttons {
                display: flex;
                margin: 40px 0;
                align-items: center;
                gap: 30px;
            }

            .button-save-share {
                display: flex;
                margin: 10px 0;
                gap: 15px;

                img {
                    max-width: 35px;
                }
            }
        }
    }

    .container-detail {
        margin: 60px 0;
        font-size: 20px;

        .title {
            font-weight: 600;
            margin-bottom: 30px;
        }

        .details {
            font-size: 18px;
            font-family: Georgia-Regular, sans-serif;
        }
    }
}

.section-shop-cart {
    margin-top: 0;
    margin-bottom: 0;

    .container {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .container-main {
        display: flex;
        flex-direction: column;
        width: 60%;

        .breadcrumb {
            margin: 40px 0;
            font-size: 12px;
            color: #E63C28;

            .content-breadcrumb {
                .arrowhead {
                    padding: 0;
                    vertical-align: -20%;
                }
            }
        }

        .container-basic-information {
            .top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 15px 0;

                .title {
                    font-size: 20px;
                    font-weight: 600;
                }

                .btn-edit {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    font-size: 12px;
                    color: #E63C28;
                    cursor: pointer;

                    img {
                        vertical-align: 0;
                    }
                }

            }

            .container-info {
                border: 1px solid #D8D8D8;
                margin: 15px 0;

                .info {
                    padding: 15px 30px;
                    font-size: 16px;
                    font-family: Georgia-Regular, sans-serif;

                    .content-info {
                        display: flex;
                        flex-direction: row;
                        gap: 15px;
                        margin: 15px 0;
                    }
                }

                .map {
                    height: 240px;
                }

                .new-info {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 10px;
                    padding: 60px;

                    .button-box {
                        background-color: #E63C28;
                        color: white;
                        padding: 5px 10px;
                        font-size: 18px;
                    }

                    .label {
                        font-size: 12px;
                        color: #E63C28;
                    }
                }
            }
        }
    }

    .container-cart {
        background-color: #FFF2EE;
        width: 40%;
        padding-bottom: 100px;

        hr {
            color: white;
            opacity: 1;
            margin: 0;
        }

        .per-section {
            display: flex;
            flex-direction: column;
            padding: 30px;
            gap: 15px;

            .content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .cart-content {
            flex-direction: row;

            .img-container {
                max-width: 100px;
                max-height: 100px;
            }

            .data-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 1;

                .name {
                    font-size: 20px;
                }

                .quantity {
                    font-size: 22px;
                    font-weight: 500;

                    .btn {
                        vertical-align: -20%;
                        padding: 0;
                    }
                }

                .price {
                    text-align: right;
                    font-size: 16px;
                }
            }
        }

        .cart-total {
            font-size: 24px;
            font-weight: 600;
        }
    }
}

#section-create-address {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    margin: auto;

    .form {
        background-color: white;
        padding: 60px;
        width: 40vw;

        .title {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }

        .main-form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0;

            select,
            input[type='text'] {
                font-size: 14px;
                padding: 5px 10px;
                margin: 5px 0;
                width: 100%;
                border: 1px solid #B6B6B6;
                border-radius: 0;
                background-color: white;
                color: #959595;
            }

            select {
                padding: 7px;
            }
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

#section-pin-address {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    margin: auto;

    .form {
        background-color: white;
        padding: 30px 0;
        width: 40vw;

        .title {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            padding: 0 60px;
        }

        .main-form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 15px 0;
            padding: 0 60px;

            input[type='text'] {
                font-size: 14px;
                padding: 5px 10px;
                margin: 5px 0;
                width: 100%;
                border: 1px solid #B6B6B6;
                border-radius: 0;
                background-color: white;
                color: #959595;
            }
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 60px;
        }
    }
}

#section-title {
    img {
        max-height: 40px;
        width: auto;
    }
}

#section-navigation {
    font-family: futura-pt, sans-serif;
    margin: 0px !important;
    background-color: $light-blue;
    z-index: 10;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 14px;

    position: sticky;
    top: -1px;

    .hide-logo {
        display: none;
    }

    .show-logo {
        display: block !important;
    }

    .img-logo {
        max-height: 36px;
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
    }

    .img-icon {
        max-height: 22px;
        width: auto;
        padding-left: 10px !important;
        padding-right: 10px;
    }

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .content {
        margin: 0;
    }

    .container-burger-logo {
        display: flex;
        flex-direction: row;
        align-items: center;

        .content {
            padding-left: 0px;
        }
    }

    .container-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 4;
        padding-left: 5px !important;

        .content {
            padding-left: 12px;
            padding-right: 12px;
            white-space: nowrap;
        }
    }

    .container-right {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        width: fit-content;

        .fs-5 {
            font-size: 22px !important;
            padding-right: 10px !important;
        }

        .photo-profile {
            padding-left: 10px;

            img {
                border: 1px solid #E63C28;
                border-radius: 50%;
                padding: 0;
            }
        }
    }
}

#section-nav-media-player {
    display: none;
    width: 100%;
    background-color: #333333;

    position: absolute;
    top: 55px;

    color: white;

    .container {
        display: flex;
        flex-direction: row;
        padding: 0;
        height: 45px;

        img {
            max-height: 15px;
        }

        .side-left {
            display: flex;
            flex-direction: row;

            .controller {
                display: flex;
                align-items: center;
            }

            .content {
                line-height: 26px;
            }

            .like-container {
                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
            }
        }

        .side-right {
            height: 100%;
            padding-right: 3px;
            display: flex;
            align-items: center;

            .btn-now-playing {
                background-color: #E63C28;
                height: 100%;
                padding: 0 22px;
                font-size: 12px;

                position: relative;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .btn-now-playing::before {
                // layout
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                margin-top: 0;
                top: -10px;
                right: 35px;
                border: .75rem solid transparent;
                border-top: none;

                // looks
                border-bottom-color: #E63C28;
            }

            .btn-open-popup {
                padding: 0 15px;
                cursor: pointer;
            }
        }
    }
}

#section-home-article1A {
    .container {
        display: flex;
        flex-direction: row;
    }

    .bg-blue {
        background-color: $light-blue !important;
    }

    .img-banner {
        padding: 0px;
    }

    .content-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 90px;
        text-align: left;
        background-color: #EBF4FF;
        width: 50%;

        .title {
            font-size: 42px;
            line-height: 48px;
            font-weight: 500;
            margin: 5px 0;
        }

        .writer {
            font-size: 18px;
            margin: 5px 0 60px 0;
        }

        .caption {
            font-size: 18px;
            font-family: Georgia-Regular, sans-serif;
        }
    }

    .content-img {
        width: 50%;
    }

    video {
        width: 50% !important;
    }
}

.section-temp-mockup {

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.section-media-player {
    margin-bottom: 0;

    .media-container {
        width: 100%;
    }

    .img-container {
        width: 100%;
        position: relative;
        height: 0;
        padding-bottom: 50% !important; //2x1 aspect ratio

        .img-fill-content {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        .btn-play-pause {
            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);

            img {
                width: 150px;
                height: auto;
            }

        }
    }

    .bottom-container {
        h3 {
            font-size: 24px !important;
        }

        h4 {
            font-size: 20px !important;
            font-weight: normal !important;
        }

        .button-container {
            padding-bottom: 45px;

            .button-box {
                display: inline-block;
                width: 180px;
                margin: 0 15px
            }
        }
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.section-mobile-slider {
    height: max-content;
    background-color: #FFD219;

    .title {
        padding: $padding-mobile;
        padding-bottom: 0;
    }

    .content {
        text-align: center;
    }

    .slider {
        padding-bottom: 40px;
    }

    .slider .slick-slide {
        padding: $padding-mobile;
    }

    .slick-dots {
        bottom: 15px;
    }

    .slick-dots li button::before {
        font-size: 12px;
        color: white;
        opacity: 1;
    }

    .slick-dots li.slick-active button::before {
        color: #E63C28;
        opacity: 1;
    }

}

.section-cosmo-channel {
    margin: 100px 0;

    .container {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;

        gap: 30px;

        width: 100%;
        height: 100%;
    }

    .media-container {
        width: 70%;
    }

    .playlist-container {
        width: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content-side {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;
        gap: 10px;
        margin: 0 !important;

        .img-container {
            width: 40%;
            height: 0 !important;
            padding-bottom: 40%;
            position: relative;

            .img-fill-content {
                width: 100%;
                height: 100%;
                object-fit: cover;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .caption-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 60%;
        }

        .content-writer {
            margin: 0;
        }
    }

}

.section-fun-fearless-female {
    margin-top: 110px;
}

.section-promo-event {
    margin-top: 110px;
    margin-bottom: 80px;
}

.section-latest-article-bottom {
    margin-bottom: 110px;
}

.section-korean-drama {
    text-align: center;

    padding-left: 0;
    padding-right: 0;

    margin-top: 0;

    .title {
        padding: 30px 0;
        font-size: 38px;
        line-height: 48px;
        font-weight: bold;
        color: #E63C28;
    }

    .caption {
        padding: 10px 30px;
    }

    .playlist-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 20px;

        text-align: left;

        .content-playlist {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            align-content: stretch;
            width: 50%;
            padding: 0 20px;

            .img-container {
                width: 100%;
                height: 0 !important;
                padding-bottom: 100%;
                position: relative;

                .img-fill-content {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }
}

.section-title-banner-caption {
    margin: 110px 0 45px 0;
    text-align: center;

    .container {
        padding: 0 !important;
    }

    .img-container {
        width: 100%;
        height: 0 !important;
        padding-bottom: 50%;
        position: relative;

        .img-fill-content {
            width: 100%;
            height: 100%;
            object-fit: cover;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    .title {
        padding: 30px 0;
    }

    .caption-title {
        padding: 15px 90px 15px 90px;
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
    }

    .caption-writter {
        color: #959595;
        font-size: 14px;
    }

    .caption {
        padding: 30px;
    }

    h2 {
        font-weight: bold;
    }
}

.media-container {
    text-align: center;

    .container {
        padding: 0;
    }

    .title {
        padding: 30px 0;
    }

    .caption {
        padding: 30px;
    }

    h2 {
        font-weight: bold;
    }
}

.pagination-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
}

.custom-section {
    padding: 15px 0;
}

.content {
    margin: 0;

    .img-container {

        .bookmark-icon {
            max-width: 44px;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }

    .caption-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .content-time {
        font-size: 14px;
        line-height: 18px;
        margin-top: 3px;
    }

    .content-title-big {
        padding: 15px 90px;
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
    }

    .content-title {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.4px;
        font-weight: 420;

        margin: 5px 0 10px 0;
    }

    .content-writer {
        font-size: 12px;
        line-height: 16px;

        margin: 0 0 40px 0;
        color: #959595;
    }

    .content-shop-brand {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.32px;
        font-weight: 500;
        color: #E63C28;

        margin: 10px 0;
    }

    .content-shop-name {
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;

        padding: 0 35px;
        margin: 10px 0;
    }

    .content-shop-price {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.4px;
        font-weight: 420;

        margin: 10px 0;
    }

    .content-img-eyelash {}

    .content-breadcrumb {
        font-size: 14px;
        line-height: 18px;
    }
}

.format-4x1 {
    margin: 45px 0;

    h2 {
        span {
            background-color: transparent !important;
            padding: 0 !important;
        }
    }

    h4 {
        font-weight: bold;
    }

    .title {
        font-size: 44px;
        line-height: 56px;
        font-weight: 500;
        letter-spacing: 3px;

        padding-top: 0;
        padding-bottom: 28px;
    }

    .container {
        padding: 15px 0;
    }

    .img-container {
        width: 100%;
        position: relative;
        height: 0;
        padding-bottom: 100%;

        .img-fill-content {
            position: absolute;
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        padding: 15px 0;
    }

    .change-direction-on-mobile {}

    .row {
        --bs-gutter-x: 28px !important;
    }
}

.format-2x2 {
    .container {
        margin: 0;
        padding: 0 !important;
    }

    .title {
        font-size: 44px;
        line-height: 56px;
        font-weight: 500;
        letter-spacing: 3px;

        padding-top: 0;
        padding-bottom: 28px;
    }

    .img-container {
        width: 100%;
        position: relative;
        height: 0;
        padding-bottom: 100%;

        .img-fill-content {
            position: absolute;
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        padding: 15px 0;
    }

    .row {
        --bs-gutter-x: 40px !important;
    }
}

#section-get-our-latest-updates {
    margin-top: 70px;
    margin-bottom: 70px;

    .container {
        background-color: #FFF2EE;
        padding: 60px 240px;
        text-align: center;

        div {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .title {
            font-size: 38px;
            line-height: 48px;
            font-weight: 500;
        }

        .main-text {
            font-family: Georgia-Regular, sans-serif;
            font-size: 14px;
            line-height: 24px;
        }
    }


}

.section-product-content {
    font-family: Georgia-Regular, sans-serif;

    img {
        max-width: 100%;
    }

    .container {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .numbering {
        border-radius: 50%;
        padding: 3px 15px;
        background-color: #E63C28;
        color: white;
        font-size: 24px;
    }

    .title {
        padding: 10px 0;
    }

    .caption {
        padding: 30px 0;
        font-size: 18px;

        .sub-title {
            color: #E63C28;
            font-size: 24px;
        }
    }

    .prize-btn {
        border: 1px solid #E63C28;
        font-weight: bold;
        padding: 12px 32px;
        font-size: 14px;
        cursor: pointer;
        margin: 15px 0;
    }
}



.section-sub-channel {
    .sub-title {
        font-size: 12px;
        color: #E63C28;
    }

    .title {
        padding-top: 5px !important;
    }
}

#section-radio-top {
    margin-top: 0;
    background-color: #FEF0F0;

    .media-container {
        padding: 80px 0;

        .title {
            padding: 15px 0;
        }

        .caption {
            padding: 40px 0;
        }

        .caption-title {
            font-size: 24px;
            color: #E63C28;
            font-weight: 500;
        }

        .caption-time {
            font-size: 14px;
            color: #959595;
        }

        .caption-current-playing {
            font-size: 12px;
            font-style: italic;
            padding: 15px 0;
        }

        .like-container {
            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
        }

        .img-container {
            width: 100%;
            height: auto;
            position: relative;

            .btn-play-pause {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                img {
                    width: 150px;
                    height: auto;
                }

            }
        }
    }
}

.section-radio-program {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 30px 0;
    padding-bottom: 60px;

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        background-color: #D2EBE6;
        margin: 0 30px 30px 0;

        .content-img {
            width: 250px;
            height: 250px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .content-text {
            padding: 50px;
            width: 275px;

            .time {
                font-size: 20px;
                color: black;
            }

            .title {
                font-size: 24px;
                line-height: 24px;
                color: #E63C28;
            }
        }
    }
}

#section-radio-live {
    background-color: #FEF0F0;
    padding: 60px 0 100px 0;
    margin-top: 0;

    .container-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 30px;

        .like-button {
            background-color: white;
            border-radius: 50%;
            padding: 8px 10px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            margin-top: 10px;
        }
    }

    .container-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        font-weight: 600;

        .title {
            text-align: left;
            font-size: 38px;
        }

        .time {
            text-align: left;
            font-size: 24px;
        }
    }

    /* .title {
		padding-bottom: 60px;
		text-align: center;
		font-size: 36px;
		font-weight: 600;
	} */
}

.section-radio-content {
    .container-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 30px;

        .title {
            text-align: left;
            font-size: 38px;
            font-weight: bold;
        }

        .time {
            text-align: right;
            font-size: 24px;
            color: #959595;
        }
    }

    .content {
        font-size: 18px;
        display: flex !important;
        justify-content: flex-start;

        .content-text {
            margin: auto;
        }
    }

    #slideLeft,
    #slideRight {
        cursor: pointer;
    }

    #slideLeft:hover,
    #slideRight:hover {
        filter: invert(34%) sepia(100%) saturate(4967%) hue-rotate(352deg) brightness(99%) contrast(83%);
    }

}

.section-podcast {
    margin-top: 60px;

    .title-main {
        padding-bottom: 30px;
        font-size: 36px;
        font-weight: bold;
    }

    .content {
        .content-img {
            width: 100%;
            padding-top: 100%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .content-text {
            margin-bottom: 30px;

            .caption-title {
                font-size: 20px;
                padding: 15px 0;
            }

            .caption-content {
                font-family: Georgia-Regular, sans-serif;
                font-size: 18px;
                color: #959595;
            }
        }
    }
}

.section-radio-podcast-page {
    padding-top: 0px;
    margin-top: 48px;

    .title-main {
        padding-bottom: 15px;
        font-size: 36px;
        font-weight: 600;
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 0;

        .container-img {
            width: 40%;
            padding-top: 40%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .container-text {
            font-family: Georgia-Regular, sans-serif;
            padding: 0 30px;
            width: 60%;

            .episode {
                font-family: futura-pt, sans-serif;
                font-size: 20px;
            }

            .title {
                font-family: futura-pt, sans-serif;
                font-size: 24px;
                padding-bottom: 15px;
            }

            .subtitle {
                font-size: 18px;
                color: #959595;
            }
        }
    }

}

.section-program-podcast-detail {
    display: flex;
    flex-direction: column;

    text-align: center;
    padding: 0;
    margin-bottom: 30px;

    img {
        max-width: 100%;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 500;
    }

    .date {
        font-size: 14px;
        line-height: 24px;
        padding-top: 0 !important;
        padding-bottom: 30px !important;
    }

    .content {
        padding: 15px 0;
    }

    .caption-main {
        font-family: Georgia-Regular, sans-serif;
        font-size: 16px;
        text-align: left;
    }
}

#section-digital-magazine-menu {
    background-color: #333333;
    color: white;
    height: 45px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 55px;
    z-index: 10;

    .container {
        display: flex;
        justify-content: space-between;

        font-size: 14px;

        .title-menu {
            display: flex;
            flex-grow: 1;
            text-transform: uppercase;

            .label {
                font-weight: 600;
                color: #E63C28;
                margin-right: 5px;
            }
        }

        .nav-menu {
            margin-left: 25px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            cursor: pointer;

            .img-icon {
                margin-left: 10px;
            }
        }
    }
}

#section-digital-magazine-menu-expand {
    background-color: black;
    color: white;
    width: 100%;
    padding: 30px 0;
    display: flex;
    align-items: center;
    position: fixed;
    top: 100px;
    display: none;
    z-index: 10;

    .main-container {
        display: flex;
        justify-content: space-between;
        gap: 80px;

        font-size: 14px;

        .container-article-main {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 60%;

            .article-main {
                display: flex;
                cursor: pointer;

                .img-container {
                    width: 25%;
                    height: 0 !important;
                    padding-bottom: 25%;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }

                .text-content {
                    width: 75%;
                    padding: 10px;
                    padding-left: 20px;
                    margin: auto;

                    .title {
                        color: #E63C28;
                        font-weight: 600;
                        text-transform: uppercase;
                        line-height: 18px;
                    }

                    .caption {
                        text-transform: uppercase;
                        line-height: 18px;
                    }
                }
            }

            .article-main:hover {
                background-color: #E63C28;
            }

            .article-main:hover .text-content .title {
                color: white;
            }
        }

        .container-article-more {
            flex-grow: 1;

            .header {
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 15px;
            }

            .content-article-more {

                .content-article {
                    padding-bottom: 15px;

                }
            }
        }
    }
}

#section-digital-magazine-main {

    .banner-container {
        display: flex;
        align-items: center;
        height: 75vh;

        .banner-text {
            display: flex;
            flex-direction: column;
            width: 40%;

            .text-edisi {
                font-weight: lighter;
                font-size: 20px;
            }

            .text-title {
                margin: 20px 0;

                h2 {
                    line-height: 55px;

                    span {
                        padding-left: 10px !important;
                        padding-right: 10px !important;
                    }
                }
            }

            .text-caption {
                width: 75%;
                font-size: 18px;
                font-family: Georgia-Regular, sans-serif;
                line-height: 28px;
            }
        }

    }

    .digital-magazine-content {
        display: flex;
        flex-direction: column;
        padding: 0 120px;

        .title {
            text-align: center;
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 30px;
        }

        .content {
            font-size: 18px;
            font-family: Georgia-Regular, sans-serif;
            line-height: 28px;
            margin: 30px 0;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .bottom-banner {
        display: flex;
        background-color: #EBF4FF;

        .container {
            display: flex;
        }

        .banner-img-container {
            width: 50%;
            display: flex;
            justify-content: flex-start;

            img {
                max-width: 100%;
            }
        }

        .banner-txt-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .content-container {
                margin: 60px;

                .tag {
                    color: #E63C28;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: 0.4px;
                    margin: 15px 0;
                }

                .title {
                    font-size: 42px;
                    font-weight: 500;
                    letter-spacing: -0.8px;
                    line-height: 48px;
                    margin: 15px 0;
                }

                .content {
                    font-size: 18px;
                    font-family: Georgia-Regular, sans-serif;
                    line-height: 28px;
                    margin: 15px 0;
                }

                .button-container {
                    margin: 45px 0;
                }
            }
        }
    }

    h2 {
        margin-bottom: 30px !important;
        font-weight: 500 !important;
        font-size: 30px !important;
        padding: 0 !important;

        span {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

#section-digital-magazine-inthisedition {
    .title {
        text-align: center;
        margin: 60px auto;
    }
}

#section-article-highlight {
    display: flex;
    flex-direction: column;

    text-align: left;

    margin-top: 65px;

    h2 {
        font-size: 24px !important;
        letter-spacing: 2px !important;
        font-weight: 500 !important;

        span {
            padding: 0;
        }
    }

    ul {
        list-style-type: disc;
    }


    img {
        //max-width: 100%;
        width: 100% !important;
        height: auto;
    }

    .highlight-green {
        background-color: #D2EBE6;
        padding: 5px 15px;
        font-size: 14px;
        width: fit-content;
    }

    .highlight-red {
        background-color: #FAC8BE;
        padding: 5px 15px;
        font-size: 14px;
        width: fit-content;
    }

    .highlight-yellow {
        background-color: #FFD219;
        padding: 5px 15px;
        font-size: 14px;
        width: fit-content;
    }


    .share-icon {
        padding-bottom: 15px !important;

        img {
            margin: 0 10px 0 0;
            max-width: 35px;
            height: auto;
            cursor: pointer;
        }
    }

    .breadcrumb {
        margin-top: 0 !important;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: bold;
    }

    .writter {
        font-size: 14px;
        line-height: 24px;
    }

    .content {
        padding: 0;
        margin: 10px 0;
    }

    .caption-highlight {
        font-size: 20px;
        line-height: 24px;
        padding-left: 15%;
        padding-right: 15%;
    }

    .caption-main {
        font-size: 19px;
        line-height: 32px;
        text-align: left;

        font-family: Georgia-Regular, sans-serif;
    }

    iframe {
        width: 100%;
        max-width: 100%;
    }
}

.single-post blockquote.tiktok-embed:before {
    display: none;
}

.fixedImage {
    width: 179px;
    height: 179px;
    border-radius: 50%;
    background-color: #fff;
    background-size: cover;
    background-position: center;
}

@media (max-width: 767px) {
    .fixedImage {
        width: 90px;
        height: 90px;
    }

    .entry-title {
        padding-left: 0px;
    }

    h2,
    .h2,
    h2 span {
        font-size: 28px !important;
    }
}

@media (max-width: 320px) {
    .entry-title {
        margin-left: -22px;
    }
}

.section-article-poll-question {
    text-align: center;

    .container {
        padding: 0;
    }

    .question {
        margin: 15px 0;
        padding: 20px;
        font-size: 24px;
    }

    .answer {
        margin: 15px 0;
        padding: 15px;
        border: 2px solid #E63C28;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
    }
}

#section-article-quiz-start {
    text-align: center;
    padding-bottom: 30px;

    .container {
        padding: 0;

        .title {
            font-size: 38px;
            font-weight: bold;
            line-height: 48px;
            padding: 80px 30px 50px 30px;
        }

        .image {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .question {
            padding: 30px 60px;
            font-size: 18px;
            font-family: Georgia-Regular, sans-serif;
        }
    }

    .submit-container {
        padding: 30px;
    }
}

#section-article-quiz {
    text-align: center;
    padding-bottom: 30px;

    .container {
        padding: 0;
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        font-size: 18px;
        font-weight: bold;

        .nav-arrow {
            cursor: pointer;
        }

        .nav-arrow:hover {
            color: #E63C28;
        }
    }

    .image {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .question {
        padding: 20px 15%;
        font-size: 18px;
    }

    .answers-container {
        padding: 15px 15%;

        .answer-content {
            margin: 15px 0;
            padding: 15px;
            background-color: white;
            border: none;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .submit-container {
        padding: 30px;
    }
}

#section-article-quiz-trivia {
    text-align: center;
    margin-bottom: 120px;

    .header {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        font-size: 18px;
        font-weight: bold;

        .nav-arrow {
            cursor: pointer;
        }

        .nav-arrow:hover {
            color: #E63C28;
        }
    }

    .container {
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;

        .image {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;

            img {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
            }
        }

        .question {
            margin: 240px 90px 60px 90px;
            padding: 15px;
            background-color: white;
            border: none;
            font-size: 18px;
            font-weight: bold;
            position: relative;
            z-index: 3;
        }

        .answers-container {
            margin: 0 15%;
            position: relative;
            z-index: 3;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .answer-content {
                padding: 15px;
                background-color: white;
                border: none;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .submit-container {
            margin: 60px;
            position: relative;
            z-index: 3;
        }
    }

}

#section-article-quiz-result {
    text-align: center;

    .container {
        padding: 30px 0;

        .title {
            font-size: 38px;
            font-weight: bold;
            line-height: 48px;
            padding-top: 60px;
            margin: 20px;
        }

        .title-result {
            margin: 20px;
            font-size: 24px;
            font-weight: 600;
        }

        .main-result {
            margin: 20px 45px;
            font-size: 18px;
        }

        .image {
            margin: 45px 0;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .submit-container {
            padding: 45px;
        }
    }
}

.section-comments {
    .content {
        padding: 15px 0;
    }

    .img-photo {
        width: 100%;
        height: auto;
    }

    .comment-name {
        font-size: 20px;
        font-weight: bold;
    }

    .comment-date {
        font-size: 12px;
        color: #959595;
        text-align: right;
    }

    .comment-content {
        font-family: Georgia-Regular, sans-serif;
        font-size: 14px;
        line-height: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .comment-like {
        font-size: 12px;
        color: #959595;
    }

    .comment-like-value {
        font-size: 12px;
        font-weight: bold;
        color: #E63C28;
    }

    .leave-comment {
        background-color: #EBF4FF;
        padding: 40px;
        margin: 30px 0;

        textarea {
            padding: 10px;
            width: 100%;
            border: none;
        }

        .button-container {
            padding: 30px 0;
        }

        .button-box {
            color: white;
            border: none;
            background-color: #E63C28;
        }
    }
}

#section-error-404 {
    margin: 120px 0 160px 0;

    .container {
        text-align: center;

        .title {
            font-size: 100px;
            font-weight: 600;
        }

        .caption {
            font-size: 24px;
            margin: 0 0 60px 0;
        }
    }
}

#section-payment-success {
    margin: 120px 0 160px 0;

    .container {
        text-align: center;

        .title {
            font-size: 36px;
            font-weight: 600;
            margin: 15px 0;
        }

        .caption {
            font-size: 16px;
            margin: 15px 0 60px 0;
        }

        .button {
            margin: 40px 0;
            font-size: 12px;
            color: #E63C28;

            .arrowhead {
                padding: 0;
                vertical-align: -20%;
            }
        }
    }
}

#section-interest {
    margin: 0;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
    }

    .title {
        font-size: 36px;
        font-weight: bold;
    }

    .caption {
        font-size: 18px;
    }

    .main-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 60px 20px !important;
        width: 940px;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14%;
        padding: 0;
    }

    .btn-container {
        text-align: center;
    }

    input[type="checkbox"] {
        display: none;
        visibility: hidden;
    }

    /* Toggle Control */

    .toggle-control {
        position: relative;
        float: right;
        margin: 7px;
        background: #FDEDEA;
        padding: 10px;
        border-radius: 2px;
        cursor: pointer;
        text-transform: uppercase;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 13px;
        word-wrap: anywhere;
    }

    input[type="checkbox"]+.toggle-control:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #E6568C;
        left: 10px;
        top: 10px;
        margin: -.25rem 0 0 0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        border-radius: 25%;
    }

    input[type="checkbox"]:checked+.toggle-control:before {
        content: "\2714";
        color: white;
        background: #E63C28;
        border: 1px solid #E6568C;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#section-footer {
    font-family: futura-pt, sans-serif;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    background-color: white;

    border-top: 1px solid #E63C28;
    position: relative;
    z-index: 9;

    .img-logo {
        max-height: 42px;
        width: auto;
    }

    .parent {
        padding: 0px !important;
    }

    .child {
        margin: 0px;
        padding: 60px 0px 30px 0px;

        .mid-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 25px;
            margin: 45px 0;

            .content-menu {}

            .logo-partner-container {
                margin: auto 0;
                min-width: 10%;

                img {
                    width: 100%;
                    max-height: 100px;
                }
            }
        }


    }

    .row {
        padding-bottom: 15px;
    }

    h5 {
        color: #1D1D1D;
    }
}

textarea::-webkit-input-placeholder {
    color: #959595;
}

textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #959595;
}

textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #959595;
}

textarea:-ms-input-placeholder {
    color: #959595;
}

textarea::placeholder {
    color: #959595;
}

.wavy-underline {
    text-decoration: none;
    padding-bottom: 10px;
    background: url("/assets/icon-underline.png") bottom repeat-x;
}

/* @media only screen and (max-width: 900px) and (min-width: 769px) {
	.container {
		padding-right: $padding-tablet;
		padding-left: $padding-tablet;
	}
} */


@media only screen and (max-width: 1365px) {
    #skin-ads-container {
        display: none;
    }
}

// @media only screen and (max-width: 768px) {
@media only screen and (max-width: 1099px) {

    blockquote {
        background: #FEF0F0;
        padding: 30px;
        margin: 60px auto;
        margin-top: 90px;
        width: 90%;
        font-size: 14px;
        letter-spacing: 0.39px;
        line-height: 18px;
    }


    blockquote.style2 {
        padding: 60px 15px;
        margin: 30px auto;
        font-size: 24px;
        letter-spacing: 1.44px;
        line-height: 30px;
    }


    .container {
        padding-right: $padding-mobile;
        padding-left: $padding-mobile;

        .main-side-container {
            .main-side-left {
                width: 100%;
            }

            .main-side-right {
                flex-grow: 0;
                display: none;
            }
        }
    }

    .underline-wave {
        text-decoration: none;
        padding-bottom: 3px;
        background-size: 95px !important;
    }

    section {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: 0;
        margin-left: 0;

        .container {
            padding: 0 $padding-mobile;
        }
    }

    .section-custom {
        padding: 20px 0;
        margin: 40px 0;
    }

    .high-margin {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    #skin-ads-container {
        display: none;
    }

    .section-ad-banner {
        margin-top: 30px !important;
        margin-bottom: 30px !important;

        .container {
            padding: 8px 0;
        }

        .content-ad-banner {
            margin: 0 !important;
            padding: 0 !important;
            // width: 320px;
            // height: 100px;
            width: auto;
            height: auto;

            .ad-mockup {
                width: 320px;
                height: 50px;
            }
        }
    }

    .custom-section {
        padding: 15px 0;
        margin: 40px 0;
    }

    .content {
        margin: 10px 0;

        .img-container {

            .bookmark-icon {
                max-width: 32px;
                height: auto;
            }
        }

        .content-time {
            font-size: 12px;
            line-height: 14px;
        }

        .content-title-big {
            padding: 6px 30px;
            font-size: 16px;
            line-height: 20px;
        }

        .content-title {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.32px;

            margin-top: 3px;
            margin-bottom: 3px;

            /* overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis; */
        }

        .content-writer {
            margin: 0;
        }

        .content-shop-brand {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0px;
            margin: 5px auto;
        }

        .content-shop-name {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.32px;

            padding: 0 10px;
            margin: 5px auto;
        }

        .content-shop-price {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.24px;
            background-color: white;
            width: fit-content;

            padding: 0 5px;
            margin: 5px auto;
        }

        .content-img-eyelash {
            img {
                max-width: 50px;
            }
        }
    }

    .format-2x2 {
        .title {
            font-size: 28px;
            line-height: 35px;

            padding-top: 0;
            padding-bottom: 10px;
        }

        .row {
            --bs-gutter-x: 20px !important;
        }
    }

    .format-4x1 {
        margin: 15px 0;

        .container {
            padding: 0;
        }

        .title {
            font-size: 28px;
            line-height: 35px;

            padding-top: 10px;
            padding-bottom: 10px;
        }

        .change-direction-on-mobile {
            display: flex;
            flex-direction: row;

            .img-container {
                width: 30% !important;
                padding-bottom: 30% !important;
            }

            .caption-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 70% !important;
                padding-left: 10px;
            }
        }

        .row {
            --bs-gutter-x: 20px !important;
        }
    }

    .section-fun-fearless-female {
        margin-top: 30px;
    }

    .section-promo-event {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .section-latest-article-bottom {
        margin-bottom: 30px;
    }

    .section-korean-drama {
        .title {
            padding: 20px 0 10px 0;
        }

        .caption {
            padding: 10px 30px;
        }

        .img-container {
            width: 100%;
            height: 0 !important;
            padding-bottom: 100%;
            position: relative;

            .img-fill-content {
                width: 100%;
                height: 100%;
                object-fit: cover;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .playlist-container {
            padding: 0;
            justify-content: space-between;
            gap: 5px;

            .content-playlist {
                width: 47%;
                padding: 0;
            }
        }
    }

    .pagination-container {
        padding: 30px 0 0 0;
        font-size: 12px;
    }


    #section-popup-page {

        width: 80vw;

        .container {

            padding: 40px 20px;

            .title {
                font-size: 16px;
                padding-bottom: 30px;
            }
        }
    }

    #section-popup-player {
        padding: 45px 0;

        .window-title {
            font-size: 18px;
            padding: 15px;
        }

        .window-content {

            .title {
                font-size: 18px;
                padding: 0
            }

            .time {
                font-size: 17px;
            }
        }

    }

    #section-popup-profile {

        .container {
            padding: 30px 60px;

            .menu {
                padding: 10px;
                margin: 10px 0;
                font-size: 16px;
            }
        }
    }

    #section-search-page {

        width: 100vw;

        .container {
            .search-container {
                padding: 30px;
                width: 100%;
            }

            .title {
                font-size: 18px;
                padding-bottom: 0px;
            }

            input {
                padding: 10px;
                width: 100%;
                font-size: 12px;
            }

            button {
                padding: 8px 12px;
                font-size: 12px;
            }

            .dropdown-menu {
                font-size: 12px;
                width: 88%;
            }
        }
    }

    #section-search-result {
        margin-top: 0;

        .top-container {
            padding-top: 20px;
            padding-bottom: 20px;

            .result-query {
                font-size: 18px;
            }

            .result-category {
                font-size: 14px;
            }
        }

        .result-container {
            padding-top: 20px;

            .content {
                padding-bottom: 0;
            }
        }

    }

    .section-profile {

        #banner-bg {
            padding: 20px 0 0 0 !important;

            .container {
                padding-bottom: 40px;
            }
        }

        .photo-container {
            margin-top: -60px;
        }

        .photo-profile {
            width: 120px;
            height: 120px;
        }

        .edit-profile-container {
            font-size: 12px;
            gap: 10px;

            .button-content {
                gap: 3px;

                img {
                    padding: 0 5px;
                    max-width: 20px;
                    max-height: 10px;
                    height: auto;
                }
            }

        }
    }

    #section-profile-history {
        margin: 30px 0;
    }

    #section-profile-my-comments {

        .container-comments {
            display: flex;
            width: 100%;
            margin: 45px 0;

            .content-image {
                width: 20%;
                position: relative;
                height: 0;
                padding-bottom: 20%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;

                    width: 100%;
                    height: 100%;

                    object-fit: cover;
                }
            }
        }

        .side-right {
            flex-direction: column;
        }

        .content-main {
            width: 80%;
            padding: 0 15px;

            .comment-name {
                font-size: 14px;
            }

            .comment-date {
                font-size: 12px;
            }

            .comment-content {
                font-size: 14px;
            }
        }

        .button-container {
            margin: 10px 15px;

            .button-box {
                font-size: 10px;
                padding: 5px 30px;
            }
        }

    }

    #section-profile-see-all {
        .title {
            padding-bottom: 5px;
        }
    }

    #section-profile-cover-edit {
        width: 100%;
        padding: 0 $padding-mobile;

        .title {
            font-size: 16px;
        }

        .button-cover {
            width: 120px;
            padding: 12px 20px;
            font-size: 12px;

        }
    }

    #section-profile-edit {
        padding: 10% $padding-mobile;
        width: 100%;

        .container {
            padding: 20px;
            box-shadow: none;
        }

        .title {
            font-size: 16px;
        }

        label {
            font-size: 10px;
            transform: translate(15px, 12px);
            padding: 5px;
        }

        .btn-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 50px 0 30px 0;
        }


    }

    #section-profile-address {
        .top {
            .title {
                font-size: 16px;
            }
        }

        .container-info {
            flex-direction: column;
            margin: 30px 0;
            gap: 30px;

            .info {
                flex-grow: 1;

                .content-info {
                    font-size: 16px;
                    font-family: Georgia-Regular, sans-serif;
                }

                .type {
                    font-size: 20px;
                    font-weight: 600;

                    .tag-default {
                        font-size: 12px;
                        font-weight: normal;
                        font-family: futura-pt, sans-serif;
                        color: #A6A6A6;
                        border: 1px solid #A6A6A6;
                        padding: 1px 3px;
                        margin: 0 15px;
                    }
                }

                .note {
                    margin: 30px 0;
                }

                .buttons {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;
                    justify-content: space-between;

                    .btn-content {
                        display: flex;
                        flex-direction: row;
                        gap: 15px;
                    }

                    .btn {
                        display: flex;
                        flex-direction: row;
                        cursor: pointer;
                        font-size: 12px;
                        color: #E63C28;
                        gap: 5px;
                        padding: 0;

                        img {
                            vertical-align: 0%;
                        }
                    }
                }
            }

            .map {
                width: 100%;
            }
        }

        .button-save-container {
            margin: 40px 0;
            width: 100%;
        }
    }

    .section-shop {
        font-size: 16px;

        .panel-control-floating {
            display: flex;
        }

        .hide-panel-right {
            left: -100% !important;
            right: -100% !important;
        }

        #container-sort {
            width: 80%;
            left: 0;

            .button-container {
                margin: 40px 0;
            }
        }

        #container-filter {
            position: fixed;
            height: 100%;
            width: 80%;
            left: -80%;
            top: 0;
            z-index: 11;
            transition: 0.5s;
            background-color: white;
            padding: 45px;
            margin: 0;
        }

        .container-main {
            width: 100%;

            .result {
                margin-bottom: 10px;
            }

            .main {
                .content-items {
                    margin-bottom: 20px;

                    .brand-container {
                        font-size: 12px;
                        margin: 5px auto;
                    }

                    .name-container {
                        font-size: 16px;
                        margin: 5px auto;
                        line-height: 18px;
                    }

                    .price-container {
                        font-size: 12px;
                        margin: 5px auto;
                    }
                }
            }
        }

    }

    .section-shop-detail {
        .breadcrumb {
            margin-top: 20px;
        }

        .container-main {
            flex-direction: column;
            gap: 30px;

            .container-pic {
                width: 100%;

                .thumbs-pic {
                    gap: 15px;

                    .nav-button {
                        display: none;
                    }
                }
            }

            .container-data {
                width: 100%;

                .brand {
                    font-size: 12px;
                }

                .name {
                    font-size: 28px;
                    margin: 5px 0;
                }

                .price {
                    font-size: 16px;
                    margin: 5px 0;
                }

                .description {
                    font-size: 16px;
                    margin: 15px 0;
                }

                .quantity {
                    gap: 30px;
                    margin: 20px 0;
                    justify-content: space-between;
                }

                .buttons {
                    .button-add {
                        width: 100%;

                        .button-box {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .container-detail {
            margin: 60px 0;
            font-size: 20px;

            .details {
                font-size: 16px;
            }
        }
    }

    .section-shop-cart {
        .container {
            flex-direction: column;
            padding: 0;
        }

        .container-main {
            width: 100%;
            padding: 0 $padding-mobile;

            .breadcrumb {
                margin: 20px 0;
            }

            .container-basic-information {
                .top {
                    .title {
                        font-size: 16px;
                    }
                }
            }
        }

        .container-cart {
            width: 100%;

            .per-section {
                padding: $padding-mobile;

                .content {
                    margin: 0;
                }
            }

            .cart-content {
                .data-container {
                    .name {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }

            .cart-total {
                font-size: 16px;
            }
        }
    }

    #section-create-address {
        width: 100vw;
        padding: $padding-mobile;

        .form {
            width: 100%;
            padding: $padding-mobile;

            .title {
                font-size: 16px;
            }

            .main-form {
                gap: 10px;
                margin: 30px 0;

                select,
                input[type='number'],
                input[type='text'] {
                    font-size: 14px;
                    padding: 5px 10px;
                    margin: 5px 0;
                    width: 100%;
                    border: 1px solid #B6B6B6;
                    border-radius: 0;
                    background-color: white;
                    color: #959595;
                }

                select {
                    padding: 7px;
                }
            }
        }
    }

    #section-pin-address {
        width: 100vw;
        padding: $padding-mobile;

        .form {
            width: 100%;
            padding: $padding-mobile;

            .title {
                font-size: 16px;
            }

            .main-form {
                gap: 10px;
                margin: 30px 0;
                padding: 0;

                select,
                input[type='number'],
                input[type='text'] {
                    font-size: 14px;
                    padding: 5px 10px;
                    margin: 5px 0;
                    width: 100%;
                    border: 1px solid #B6B6B6;
                    border-radius: 0;
                    background-color: white;
                    color: #959595;
                }
            }
        }
    }

    #section-navigation {
        background-color: white;

        font-size: 12px;

        img {
            padding-left: 8px;
            padding-right: 8px;
        }

        .img-logo {
            max-height: 20px;
        }

        .container {
            padding: 0 !important;
            flex-wrap: wrap;
        }

        .container-menu {
            background-color: $light-blue;
            order: 3;
            padding: 15px 0;

            /* Hide scrollbar for IE, Edge and Firefox */
            /* -ms-overflow-style: none;
			scrollbar-width: none; */

            /* .content {
				padding-left: 20px;
				padding-right: 20px;
			} */
        }

        .container-menu::-webkit-scrollbar {
            display: none;
        }

        .container-burger-logo {
            padding: 10px 0;

            .content {
                padding-left: 20px;
            }
        }

        .container-right {
            order: 2;
            padding-right: $padding-mobile;

            .content {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    #section-nav-media-player {

        .container {
            height: 45px;
            padding-left: $padding-mobile !important;

            img {
                max-height: 14px;
            }

            .side-left {
                width: 52%;

                .content {
                    height: 26px;
                    overflow: hidden;
                }
            }

            .side-right {
                width: 150px;
                padding-right: 0;

                .btn-now-playing {
                    padding: 0 12px;
                }

                .btn-now-playing::before {
                    margin-left: 0;
                    top: -10px;
                    right: 55px;
                }

                .btn-open-popup {
                    padding: 0 0 0 5px;
                    cursor: pointer;

                    img {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .section-page-show-all {

        .container-author-detail {
            margin-bottom: 60px;

            .left-content {
                width: auto;
                margin-right: 15px;
            }

            .right-content {
                width: auto;
            }

            .author-img {
                img {
                    width: 90px;
                    height: 90px;
                }
            }

            .author-name {
                font-size: 22px;
                line-height: 28px;
            }

            .author-medsos {
                font-size: 12px;
                margin-bottom: 0px;
            }

            .author-description {
                font-size: 16px;
                margin-top: 15px;
            }
        }
    }

    #section-page-banner {
        margin: 0 0 30px 0;

        .header {
            padding-bottom: 100%;
        }

        .title {
            padding: 30px;
        }

        .container {
            padding: 20px 10% 20px 10%;
        }

        .container-2 {
            padding: 20px $padding-mobile;
        }

        .edit-banner-container {
            img {
                max-width: 30px;
                height: auto;
            }
        }

        h2 {
            line-height: 50px;
            font-size: 30px;
        }

        .navigation {
            gap: 15px;

            .dot-active {
                height: 8px;
                width: 8px;
            }

            .dot {
                height: 8px;
                width: 8px;
            }
        }
    }

    #section-page-subscribe {
        background-position: top left;
        background-size: contain;

        .container {
            flex-direction: column;
        }

        .empty {
            height: 30vh;
        }

        .main {
            width: 100%;
            padding: 0 0 80px 0;

            .title {
                font-size: 24px;
                padding: 15px 0;
            }

            .content {
                font-size: 14px;
                padding-bottom: 5px;
            }

            .btn-container {
                padding-bottom: 5px;
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .button-box {
                    width: 95px;
                    padding: 2px 5px;
                }
            }
        }

        .title {
            font-size: 24px;
            font-weight: bold;
            padding: 15px 0;
        }

        .title-small {
            font-size: 20px;
        }

        .contact {
            .label {
                font-size: 12px;
            }

            .value {
                font-size: 18px;
                font-weight: 600;
                padding-bottom: 10px;
            }
        }
    }

    #section-page-contact-us {
        .container {
            flex-direction: column;
            padding: 0;

            .side-left,
            .side-right {
                width: 100%;
                padding: 40px $padding-mobile;
            }

            .contact-data {
                .title {
                    font-size: 24px;
                    padding: 10px 0;
                }

                .content {
                    font-size: 14px;
                    margin: 10px 0;
                }

                .sub-content-container {
                    padding: 15px 0;

                    .sub-title {
                        font-size: 20px;
                    }

                    .sub-content {
                        font-size: 18px;
                        padding: 5px 0;
                    }
                }

            }

            .contact-form {
                padding: 10px 30px;

                .title {
                    font-size: 24px;
                    margin: 20px;
                }

                input,
                textarea {
                    width: 100%;
                    margin: 5px 0;
                    padding: 5px 8px;
                }
            }
        }
    }

    #section-about-content {
        margin-top: 45px;

        .container-content {
            padding: 0 $padding-mobile;
        }

        .content-main {
            font-size: 14px;
            margin-bottom: 10px;
        }

        .content-break {
            text-align: center;
            padding: 10px 0;

            .line-break {
                width: 30%;
            }
        }

        .content-highlight {
            padding: 10px 30px;
            font-size: 14px;
        }
    }

    #section-about-team {
        text-align: center;

        .container-content {
            margin: 30px 0;
            padding: 0 $padding-mobile;
        }

        .main {
            padding: 20px;
        }

        .title {
            font-size: 20px;
        }

        .content {
            font-size: 14px;
            padding: 20px;
        }

        .profiles {
            padding: 0;
            gap: 15px;
            justify-content: flex-start;

            .leader-layout {
                margin: 10px 0 !important;
            }

            .profile-container {
                width: 45%;
                margin: 10px 0;

                .photo {
                    img {
                        max-width: 100px;
                    }
                }

                .name {
                    font-size: 14px;
                    margin-top: 5px;
                }

                .profile-title {
                    font-size: 11px;
                }
            }

            .leaders {
                .profile-container {
                    margin: auto;
                }
            }
        }


    }

    #section-home-article1A {
        padding-top: 0px;

        .container {
            flex-direction: column;
        }

        .img-banner {
            width: 100%;
        }

        .content-text {
            padding: 20px 60px;
            order: 2;
            width: 100%;
            align-items: center;
            text-align: center;

            .title {
                font-size: 16px;
                line-height: 20px;
                margin: 5px 0;
            }

            .writer {
                font-size: 10px;
                margin: 5px 0;
            }
        }

        .content-img {
            width: 100%;
        }

        video {
            width: 100% !important;
        }
    }

    .section-title-banner-caption {
        margin: 45px 0;
        padding: 0 $padding-mobile;

        .img-container {
            padding-bottom: 100%;
        }

        .title {
            padding: 15px;
        }

        .caption {
            padding: 15px;

            img {
                max-width: 45px;
            }
        }

        .caption-title {
            padding: 6px 30px 8px 30px;
            font-size: 16px;
            line-height: 20px;
        }

        .caption-writter {
            font-size: 12px;
        }

        h4 {
            color: #959595;
        }
    }

    .section-media-player {
        margin-top: 30px;

        .title {
            padding: 20px 0 10px 0;
        }

        .img-container {
            width: 100%;
            height: 0 !important;
            padding-bottom: 100% !important;
            position: relative;

            .img-fill-content {
                width: 100%;
                height: 100%;
                object-fit: cover;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .bottom-container {
            .title {
                padding: 15px 0;
            }

            .button-container {
                padding-bottom: 30px;

                .button-box {
                    width: 120px;
                    margin: 0 10px
                }
            }
        }

        .content {
            padding: 10px;
        }
    }

    .section-cosmo-channel {
        margin: 45px 0;

        h2 {
            margin-bottom: 0;
        }

        .container {
            flex-direction: column;
            gap: 0;
        }

        .media-container {
            width: 100%;
            margin-bottom: 15px;

            .title,
            .caption {
                padding: 15px 30px;
            }

            .img-container {
                width: 100%;
                height: 0 !important;
                padding-bottom: 100%;
                position: relative;

                .img-fill-content {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
        }

        .playlist-container {
            width: 100%;
            gap: 15px;

            .img-container {
                width: 30%;
                padding-bottom: 30%;
            }

            .caption-container {
                width: 70%;
            }
        }

    }

    #section-get-our-latest-updates {
        margin: 40px $padding-mobile;
        margin-top: 20px;

        .container {
            padding: 30px !important;

            .title {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }

    .section-product-content {
        .container {
            padding: 0 $padding-mobile;
        }

        .numbering {
            padding: 0 8px;
            font-size: 18px;
        }

        .caption {
            padding: 20px 0;
            font-size: 14px;

            .sub-title {
                font-size: 18px;
            }
        }

        .prize-btn {
            padding: 5px 20px;
            font-size: 10px;
        }
    }


    .section-product-reaction {
        padding: 0 $padding-mobile;

        .container {
            height: 60px;
        }
    }

    .section-program-podcast-detail {
        padding-top: 20px !important;
        margin-bottom: 0px;

        font-size: 12px;

        .container {
            padding: 0 $padding-mobile !important;
        }

        .title {
            font-size: 24px !important;
            line-height: 30px;
        }

        .date {
            font-size: 12px !important;
            line-height: 14px;
            padding-top: 10px !important;
            padding-bottom: 30px !important;
        }

        .content {
            padding: 10px 0;
        }

        .caption-main {
            font-size: 14px;
        }
    }

    #section-digital-magazine-menu {
        height: 46px;
        top: 102px;

        .container {
            font-size: 11px;

            .title-menu {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .label {
                    margin-right: 5px;
                }
            }

            .nav-menu {
                .img-icon {
                    max-width: 10px;
                }
            }
        }
    }

    #section-digital-magazine-menu-expand {
        top: 145px;
        padding: 15px 0;

        height: 100%;
        overflow-y: auto;

        .main-container {
            flex-direction: column;
            gap: 30px;

            .container-article-main {
                gap: 15px;
                width: 100%;
            }

            .container-article-more {
                padding: 0;
            }
        }
    }

    #section-digital-magazine-main {

        .banner-container {

            .banner-text {
                width: 100%;

                .text-edisi {
                    font-size: 14px;
                }

                .text-title {
                    margin: 15px 0;

                    h2 {
                        line-height: 48px;
                    }
                }

                .text-caption {
                    width: 100%;
                }
            }

        }

        .digital-magazine-content {
            padding: 0;

            .title {
                margin-bottom: 15px;
            }

            .content {
                margin: 15px 0;
            }
        }

        .bottom-banner {
            .container {
                flex-direction: column;
            }


            .banner-img-container {
                width: 100%;
                justify-content: center;
            }

            .banner-txt-container {
                width: 100%;

                .content-container {
                    width: 100%;
                    margin: 0;
                    padding: 0 $padding-mobile;

                    .tag {
                        color: #E63C28;
                        font-size: 14px;
                    }

                    .title {
                        font-size: 28px;
                        line-height: 38px;
                    }

                    .content {
                        font-size: 18px;
                    }

                    .button-container {
                        margin: 30px 0;
                    }
                }
            }
        }
    }

    #section-digital-magazine-inthisedition {
        .title {
            text-align: center;
            margin: 30px auto;
        }
    }

    #section-article-highlight {
        padding: 10px 0;


        .title {
            font-size: 20px;
            line-height: 24px;
        }

        .writter {
            font-size: 12px;
            line-height: 22px;
        }

        /* .share-icon {
			position: fixed;
			bottom: 10%;
		} */

        .content {
            padding: 0 $padding-mobile;
        }

        .caption-highlight {
            font-size: 14px;
            line-height: 18px;
            padding: 30px 15% !important;
        }

        .caption-main {
            // font-size: 16px;
            // line-height: 24px;
            font-size: 19px;
            line-height: 32px;
        }
    }

    .section-article-poll-question {
        .container {
            padding: 20px $padding-mobile;
        }

        .question {
            margin: 5px 0;
            padding: 10px;
            font-size: 14px;
        }

        .answer {
            margin: 5px 0;
            padding: 10px;
            font-size: 14px;
        }
    }

    #section-article-quiz-start {
        padding-bottom: 20px;

        .container {

            .title {
                font-size: 14px;
                line-height: 24px;
                padding: 30px;
            }

            .question {
                padding: 15px 30px;
                font-size: 12px;
            }
        }
    }

    #section-article-quiz {
        margin: 20px $padding-mobile;

        .header {
            padding: 30px 15px;
            font-size: 14px;
        }

        .question {
            font-size: 16px;
        }

        .answers-container {
            padding: 15px 15%;

            .answer-content {
                margin: 15px 0;
                padding: 15px;
                font-size: 12px;
            }
        }

        .submit-container {
            padding: 30px;
        }
    }

    #section-article-quiz-trivia {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        .header {
            padding: 30px 15px;
            font-size: 14px;
        }

        .container {

            .question {
                margin: 30px;
                padding: 10px;
                font-size: 14px;
            }

            .answers-container {

                .answer-content {
                    padding: 15px;
                    font-size: 12px;
                }
            }

            .submit-container {
                margin: 30px;
            }

        }

    }

    #section-article-quiz-result {
        margin: 20px $padding-mobile;

        .container {
            padding: 30px 0;

            .header {
                font-size: 24px;
                margin: 20px;
            }

            .title {
                font-size: 24px;
                line-height: 38px;
                padding-top: 0;
                margin: 20px;
            }

            .title-result {
                font-size: 16px;
            }

            .main-result {
                margin: 20px;
                font-size: 16px;
            }

            .image {
                margin: 30px 0;
                width: 100%;

                img {
                    max-width: 100%;
                }
            }

            .submit-container {
                padding: 45px;
            }
        }

    }

    .section-comments {
        padding: 15px;
        padding-bottom: 0;

        .comment-name {
            font-size: 14px;
        }

        .comment-date {
            font-size: 10px;
        }

        .leave-comment {

            padding: 20px;
            margin: 15px 0;

            textarea {
                padding: 5px;
            }

            .button-container {
                padding: 20px 0;
            }
        }
    }

    #section-error-404 {
        margin: 0;
        height: 80vh;

        .container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {
                font-size: 50px;
            }

            .caption {
                font-size: 16px;
                margin: 0 0 30px 0;
            }
        }
    }

    #section-interest {

        .col-4 {
            padding-left: 0;
            padding-right: 0;
        }

        .title {
            font-size: 20px;
        }

        .caption {
            font-size: 12px;
        }

        .main-content {
            padding: 50px 0px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .content {
            width: 33%;
        }

        .toggle-control {
            font-size: 11px;
        }

    }

    #section-radio-top {
        margin: 0;

        .media-container {
            padding: 40px 0;

            .title,
            .caption {
                padding: 20px 0;
            }

            .caption-title {
                font-size: 18px;
            }

            .img-container {
                width: 100%;
                height: 0 !important;
                padding-bottom: 100%;
                position: relative;

                .img-fill-content {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }

                .btn-play-pause {
                    top: 50%;

                    img {
                        width: 60px;
                        height: auto;
                    }

                }
            }
        }
    }

    .section-radio-program {
        margin: 10px 0;
        overflow-x: hidden;
        padding-bottom: 30px;

        .content {
            margin: 5px 10px;

            .content-img {
                height: 150px;
                width: 150px;

                img {
                    height: 100%;
                }
            }

            .content-text {
                padding: 15px;
                width: 150px;
                text-align: center;

                .time {
                    font-size: 12px;
                }

                .title {
                    font-size: 14px;
                    line-height: 14px;
                }
            }
        }
    }

    #section-radio-live {
        padding: 30px 0 40px 0;
        margin-top: 0;
        margin-bottom: 40px;

        .title {
            padding-bottom: 20px;
            font-size: 24px;
            font-weight: 600;
        }
    }

    .section-radio-content {
        .container-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 15px;

            .title {
                font-size: 20px;
            }

            .time {
                font-size: 12px;
            }
        }

        .content {
            font-size: 14px;
        }
    }

    .section-podcast {
        margin-top: 30px;

        .container {
            padding: 0 50px !important;
        }

        .title-main {
            padding-bottom: 0;
            font-size: 20x;
            text-align: center;
            font-weight: 600;
        }

        .content {
            padding: 0;

            .content-text {
                margin-bottom: 15px;

                .caption-title {
                    font-size: 18px;
                    padding: 15px 0;
                }

                .caption-content {
                    font-size: 14px;
                    margin-bottom: 40px;
                }
            }
        }
    }

    .section-radio-podcast-page {
        margin-top: 15px;

        .title-main {
            padding-bottom: 20px;
            font-size: 20px;
            font-weight: 600;
        }

        .content {
            padding: 0;

            .container-img {
                width: 35%;
                padding-top: 35%;
            }

            .container-text {
                width: 65%;
                padding: 10px;

                .episode {
                    font-size: 12px;
                }

                .title {
                    font-size: 18px;
                    padding-bottom: 0px;
                }

                .subtitle {
                    font-size: 12px;
                }
            }
        }

    }

    #section-footer {
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 40px;
        text-align: center;
        background-color: #EBEBEB;

        .img-logo {
            max-height: 30px;
            margin-bottom: 30px;
        }

        .child {
            margin: 0px;
            padding: 60px 50px 30px 50px !important;
            width: 100%;
            text-align: left;

            .mid-container {

                .content-menu {
                    width: 40%;
                }

                .logo-partner-container {
                    width: 40%;

                    img {
                        width: auto;
                        max-width: 100%;
                    }
                }
            }
        }

        .text-copyright {
            margin-bottom: 15px;
        }

        .row {
            padding-bottom: 0px;
        }

        .col-6 {
            padding-bottom: 8px;
        }

        .col-12 {
            padding-bottom: 20px;
        }
    }
}

@media only screen and (min-width: 1850px) {
    #section-page-banner {
        .header {
            padding-bottom: 20%;
        }
    }
}

.jp-play.player-loading,
.jp-pause.player-loading {
    background-image: url(/assets/loader.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    z-index: 1000;
}

.jp-mute:active,
.jp-unmute:active,
.jp-mute:focus,
.jp-unmute:focus {
    text-decoration: none;
}

.jp-volume-bar-wrapper {
    display: none;
    padding-right: 9px;
    cursor: pointer;
}

.jp-mute:hover~.jp-volume-bar-wrapper,
.jp-unmute:hover~.jp-volume-bar-wrapper,
.jp-volume-bar-wrapper:hover {
    display: block;
}

.jp-volume-bar {
    background: #6b5d56;
    width: 100px;
    height: 10px;
}

.jp-volume-bar-value {
    background: #ffffff;
    height: 10px;
}

.jp-no-solution {
    display: none;
}

.margin-right-20{
   margin-right: 20px;
}

.tbl-feed-header{
    display: none !important;
}