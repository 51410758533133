$colors: (
    "red": #E63C28,

) !default;

$theme-colors: (
    "info": #ccc,
);

.btn {
    &.btn-primary{
        background: #E63C28 !important;
        font-weight: bold;
        border: 2px solid #E63C28;
        padding: 10px 32px;
        color: white !important;

    }

    &.btn-primary:hover{
        background: white !important;
        color: #E63C28 !important;
    }

    &.btn-primary:focus{
        outline: none !important;
        box-shadow: none !important;
    }
}

.text-red{
    color: #E63C28 !important;
}

textarea,
input[type=text],
input[type=password],
input[type=email],
input[type=checkbox],
{
    &.form-control, &.form-check-input{
        background-color: white !important;

    }

    &.form-control:focus{
        outline: none !important;
        box-shadow: none !important;
        border-color: $red;
    }

    &.form-control:read-only{
        background-color: #ddd !important;
    }

    &.form-check-input:checked{
        background-color: $red !important;
        border-color: $red !important;
    }
}

.text-sm{
    font-size: 12px;
}
.text-gray{
    color: #666;
}


