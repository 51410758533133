h1 {
	font-family: futura-pt, sans-serif;
	font-size: 36px;
	line-height: 46px;
}

h2 {
	font-family: futura-pt, sans-serif;
	font-size: 32px !important;
}

h3 {
	font-family: futura-pt, sans-serif;
	font-size: 24px !important;
}

h4 {
	font-family: futura-pt, sans-serif;
	font-size: 14px !important;
}

h5 {
	font-family: futura-pt, sans-serif;
	font-size: 11px !important;
	line-height: 20px !important;
	color: #959595;
}

a {
	color: inherit !important;
	text-decoration: none !important;
	opacity: 1;
}

a:hover {
	color: #E63C28 !important;
	text-decoration: none !important;
}

.custom-hover {
	a:hover {
		color: inherit !important;
		opacity: 0.5;
	}
}

.font-georgia{
	font-family: Georgia-Regular, sans-serif !important;
}

.arrowhead {
	font-size: 18px;
	color: #E63C28;
	vertical-align: 10%;
	padding: 0 10px;
	line-height: 10px;
}

.dot-separator {
	color: #E63C28;
	padding: 0 5px;
}

.font-bold {
	font-weight: bold;
}

.default-link {
	color: #0000EE;
	text-decoration: underline;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.no-padding {
	padding: 0 !important;
}

.padding-top-small {
	padding-top: 10px;
}

.padding-top-mobile {
	padding-top: 0px;
}

.padding-bottom-small-mobile {
	padding-bottom: 0px;
}

.huge-margin-desktop{
	margin-top: 120px !important;
	margin-bottom: 120px !important;
}

.div-margin {
	margin: 5px 0;
}

.close-btn {
	position: absolute;
	color: white;
	top: 10px;
	right: 10px;
	font-size: 24px;
	cursor: pointer;
}

.highlight-tag {
	//background-color: #FFD219;
	color: #959595;
	padding: 0;
	font-size: 12px;
	width: fit-content;
}
.highlight-tag:hover{
	color: #E63C28;
}

.logo-medsos {
	max-width: 36px;
	max-height: 36px;
}

.img-logo {
	max-width: 100%;
	height: auto;
	object-fit: contain;
}

.img-logo-text {
	max-height: 14px;
	padding: 0px 5px 0px 5px;
	width: auto;
	vertical-align: bottom;
}

.img-fill-content {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.highlight-box {
	background-color: white;
	padding-left: 40px;
	padding-right: 40px;
	display: inline-block;
	font-size: 30px;
	line-height: 35px;
	font-weight: bold;
}

.button-quiz-selected {
	color: white;
	background-color: #E63C28 !important;
}

.button-box {
	border: 2px solid #E63C28;
	background-color: #E63C28;
	font-weight: bold;
	color: white;
	padding: 10px 32px;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
}

.button-box:hover {
	border: 2px solid #E63C28;
	background-color: transparent;
	color: #E63C28;
}

.button-box-long {
	border: 2px solid #E63C28;
	background-color: #E63C28;
	font-weight: bold;
	color: white;
	padding: 8px 60px;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
}

.button-box-long:hover {
	border: 2px solid #E63C28;
	background-color: transparent;
	color: #E63C28;
}

.flex-middle {
	display: flex;
	align-items: center;
	justify-content: center;
}

.full-width {
	width: 100%;
	margin: 0 !important;
}

.hide-on-mobile {}

.hide-on-desktop {
	display: none;
}

.hide-on-desktop-flex {
	display: none !important;
}


.marquee {
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;

	p {
		display: inline-block;
		padding-left: 100%;
		animation: marquee 15s linear infinite;
		margin: 0;
	}
}

@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(-100%, 0);
	}
}


@media only screen and (max-width: 768px) {
	h1 {
		font-size: 20px;
		line-height: 24px;
	}

	h2 {
		font-size: 18px !important;
	}

	h3 {
		font-size: 16px !important;
	}

	h4 {
		font-size: 14px !important;
	}

	h5 {
		font-size: 12px !important;
		line-height: 16px !important;
	}

	.logo-medsos {
		max-width: 24px;
		max-height: 24px;
	}

	.hide-on-mobile {
		display: none !important;
	}

	.hide-on-desktop {
		display: block;
	}

	.hide-on-desktop-flex {
		display: flex !important;
	}

	.padding-top-mobile {
		padding-top: 20px;
	}

	.padding-bottom-small-mobile {
		padding-bottom: 10px;
	}

	.huge-margin-desktop{
		margin-top: 30px !important;
		margin-bottom: 30px !important;
	}

	.button-box {
		padding: 10px 15px;
		font-size: 12px;
	}

	.button-box-long {
		padding: 10px 30px;
		font-size: 12px;
	}
}