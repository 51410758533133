// Body
$body-bg: black;

// Typography
$font-family-main: 'SpaceGrotesk',
sans-serif;
$font-family-main-medium: 'SpaceGrotesk-Medium',
sans-serif;
$font-family-main-bold: 'SpaceGrotesk-Bold',
sans-serif;

$padding-main: 0px 20px 90px 20px;

$padding-mobile: 20px;

$padding-tablet: 30px;

$line-color: rgba(200, 200, 200, 0.5);

$light-blue:#EBF4FF;

$running-text-width: 25px;
